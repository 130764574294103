// 
// 
// flickity.scss
//
//

@import "../plugins/flatpickr.min";

.flatpickr-months{
  margin: $spacer/2 0;
  .flatpickr-month, .flatpickr-next-month, .flatpickr-prev-month{
    height: auto;
    position: relative;
    &:hover{
      svg{
        fill: $primary;
      }
    }
  }
  .flatpickr-month{
    color: $body-color;
  }
}

.flatpickr-current-month{
  select{
    -moz-appearance: none !important;
  }
  .flatpickr-monthDropdown-months{
    font-weight: $font-weight-bold;
  }
  span.cur-month{
    font-weight: $font-weight-bold;
    &:hover{
      background: rgba($primary,.15);
    }
  }
}

.numInputWrapper{
  &:hover{
    background: rgba($primary,.15);
  }
}

.flatpickr-day{
  border-radius: $border-radius;
  font-weight: 500;
  color: $body-color;
  &.today{
    border-color: $primary;
    &:hover{
      background: $primary;
      border-color: $primary;
    }
  }
  &:hover{
    background: rgba($primary,.1);
    border-color: rgba(0,0,0,0);
  }
}

span.flatpickr-weekday{
  color: $body-color;
}

.flatpickr-day.selected, .flatpickr-day.startRange, .flatpickr-day.endRange, .flatpickr-day.selected.inRange, .flatpickr-day.startRange.inRange, .flatpickr-day.endRange.inRange, .flatpickr-day.selected:focus, .flatpickr-day.startRange:focus, .flatpickr-day.endRange:focus, .flatpickr-day.selected:hover, .flatpickr-day.startRange:hover, .flatpickr-day.endRange:hover, .flatpickr-day.selected.prevMonthDay, .flatpickr-day.startRange.prevMonthDay, .flatpickr-day.endRange.prevMonthDay, .flatpickr-day.selected.nextMonthDay, .flatpickr-day.startRange.nextMonthDay, .flatpickr-day.endRange.nextMonthDay{
  background: $primary;
  border-color: $primary;
}

.flatpickr-day.selected.startRange + .endRange:not(:nth-child(7n+1)), .flatpickr-day.startRange.startRange + .endRange:not(:nth-child(7n+1)), .flatpickr-day.endRange.startRange + .endRange:not(:nth-child(7n+1)){
  box-shadow: -10px 0 0 $primary;
}

.flatpickr-day.selected.startRange, .flatpickr-day.startRange.startRange, .flatpickr-day.endRange.startRange{
  border-radius: $border-radius 0 0 $border-radius;
}

.flatpickr-day.selected.endRange, .flatpickr-day.startRange.endRange, .flatpickr-day.endRange.endRange{
  border-radius: 0 $border-radius $border-radius 0;
}

input.flatpickr-input{
 + input[readonly]{
   background-color: $input-bg;
   cursor: pointer;
 }
}

.flatpickr-months{
  select, input{
    -webkit-appearance: none !important;
  }
}